import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as UsersIcon } from '@icons/wolfkit-light/users-light.svg';
import { numberAbbreviation } from '@utils/DisplayValueHelpers';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
const UserPosition = styled.span((props) => ({
    color: props.theme.palette.text.primary,
}));
const FollowingIcon = ({ count, isFollowing = undefined, followerPosition = undefined, }) => (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: UsersIcon })), text: isFollowing ? (_jsxs(_Fragment, { children: [_jsx(UserPosition, { children: numberAbbreviation(followerPosition) }), ` / ${numberAbbreviation(count)}`] })) : `${numberAbbreviation(count)}` }));
export default FollowingIcon;
